import React, { useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useContentful from '../../useContentful';
import { convertToLowercase, getBlogPreviewContent } from '../../utilities/helperFunctions';

import '../../css/mediasection.css';

const BlogsMedia = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [currentIndex, setCurrentIndex] = useState(0);
  const { getBlogs } = useContentful();
  const [blogsContent, setBlogsContent] = useState([]);
  const [currentCards, setCurrentCards] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [visibleCards, setVisibleCards] = useState(2);

  useEffect(() => {
    getBlogs()
      .then((res) => {
        setBlogsContent(res.blogContent);
        setAllCards(res.blogContent);
      })
      .catch((error) => {
        console.error('Error fetching blogs:', error);
      });
  }, []);

  useEffect(() => {
    setVisibleCards(isMobile ? 1 : 2);
  }, [isMobile]);

  useEffect(() => {
    setCurrentCards(allCards.slice(currentIndex, currentIndex + visibleCards));
  }, [allCards, visibleCards, currentIndex]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, blogsContent.length - visibleCards));
  };

  return (
    <>
      {currentCards.length >= 1 && (
        <>
          <Box mt={2} mb={4}>
            <Link to='/blogs' className='media-subHeading'>
              Blogs
            </Link>
          </Box>
          <Box className='news-media-container'>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box className='arrow-btn-container'>
                <button className='arrow-btn' onClick={handlePrev}>
                  &lt;
                </button>
              </Box>
              <Box className='media-container'>
                {currentCards.map((blog, index) => (
                  <Box key={index} className='card-container'>
                    {blog.fields?.image?.fields?.file?.url ? (
                      <a
                        href={`/blogs/${convertToLowercase(blog.fields.blogUrl)}`}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <img
                          alt={blog.fields.image.fields.description || 'Blog image'}
                          src={blog.fields.image.fields.file.url}
                          className='card-img'
                          loading='lazy'
                        />
                      </a>
                    ) : (
                      <Box className='no-image-placeholder'>
                        <Box>Blog</Box>
                      </Box>
                    )}

                    <Box className='card-content-container'>
                      <span className='article-card-heading'>{blog.fields.title}</span>
                      <Typography variant='body2' className='blog-card-content'>
                        {getBlogPreviewContent(blog.fields.blogDescription)}
                      </Typography>
                      <a
                        href={`/blogs/${convertToLowercase(blog.fields.blogUrl)}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='article-card-link'>
                        Read More
                      </a>
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box className='arrow-btn-container'>
                <button className='arrow-btn' onClick={handleNext}>
                  &gt;
                </button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default BlogsMedia;
